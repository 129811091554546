import { User, WinType } from '@kfiroo/backgammon-core'
import React from 'react'

import { Board } from './Board'
import { Confetti } from './Confetti'
import { PlayerType } from './GameApi/BaseGameApi'
import { GameOverGameApi, GameOverStats } from './GameApi/GameOverGameApi'
import { Players } from './Players'
import { useUserInfo } from './useUserInfo'

export function GameOver({
  gameApi,
  user,
}: {
  gameApi: GameOverGameApi
  user: User
}) {
  return (
    <div className="game">
      <Players players={gameApi.getPlayers()} />
      <Board board={gameApi.getBoard()} />
      <Overlay gameApi={gameApi} user={user} />
    </div>
  )
}

function Overlay({ gameApi, user }: { gameApi: GameOverGameApi; user: User }) {
  const isWinner = gameApi.isWinner()
  const isLoser = gameApi.isLoser()
  const winnerName = gameApi.getWinner().displayName
  const winType = gameApi.getWinType()
  const stats = gameApi.getStats()

  if (isWinner) {
    const winTypeName =
      winType === 'normal'
        ? 'You Won'
        : winType === 'gammon'
          ? 'Mars Winner'
          : 'Turkish Mars Winner'
    return (
      <div className="waiting">
        <h1>{winTypeName}! 🐒👑</h1>
        <button onClick={() => window.location.assign(`/`)}>Play again</button>
        {stats !== null ? <Stats user={user} stats={stats} /> : null}
        <Confetti />
      </div>
    )
  }
  if (isLoser) {
    const winTypeName =
      winType === 'normal'
        ? 'You Lost'
        : winType === 'gammon'
          ? 'Mars Loser'
          : 'Turkish Mars Loser'
    return (
      <div className="waiting">
        <h1>{winTypeName} 😭</h1>
        <button onClick={() => window.location.assign(`/`)}>Play again</button>
        {stats !== null ? <Stats user={user} stats={stats} /> : null}
      </div>
    )
  }
  const winTypeName =
    winType === 'normal'
      ? 'Won'
      : winType === 'gammon'
        ? 'Mars Winner'
        : 'Turkish Mars Winner'
  return (
    <div className="waiting">
      <h1>
        {winnerName} {winTypeName}
      </h1>
      <button onClick={() => window.location.assign(`/`)}>Play again</button>
    </div>
  )
}

const WinTypeScore: Record<WinType, number> = {
  normal: 1,
  gammon: 2,
  backgammon: 3,
}

function Stats({ stats, user }: { stats: GameOverStats; user: User }) {
  const { userInfo, loading, error } = useUserInfo(user)

  if (loading) {
    return (
      <div className="stats">
        <div>Loading...</div>
      </div>
    )
  }

  if (error || !userInfo) {
    console.error('load user info error', error)
    return null
  }

  const player = stats[PlayerType.PLAYER]
  const opponent = stats[PlayerType.OPPONENT]

  const games = Object.values(userInfo.games).filter((game) => {
    const players = game.players.map((p) => p?.email)
    return (
      game.state === 'game-over' &&
      players.includes(player.email) &&
      players.includes(opponent.email)
    )
  })

  const score = games.reduce(
    (acc, game) => {
      if (game.players[game.winner!]?.email === player.email) {
        acc[PlayerType.PLAYER] += WinTypeScore[game.winType!]
      }
      if (game.players[game.winner!]?.email === opponent.email) {
        acc[PlayerType.OPPONENT] += WinTypeScore[game.winType!]
      }
      return acc
    },
    {
      [PlayerType.PLAYER]: 0,
      [PlayerType.OPPONENT]: 0,
    },
  )

  return (
    <div className="stats">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <img
                className="avatar"
                src={player.photoURL}
                alt={player.displayName}
              />
            </th>
            <th>
              <img
                className="avatar"
                src={opponent.photoURL}
                alt={opponent.displayName}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Score:</td>
            <td>{score[PlayerType.PLAYER]}</td>
            <td>{score[PlayerType.OPPONENT]}</td>
          </tr>
          <tr>
            <td>Doubles:</td>
            <td>{player.doubles}</td>
            <td>{opponent.doubles}</td>
          </tr>
          <tr>
            <td>6 - 5:</td>
            <td>{player.sheshBesh}</td>
            <td>{opponent.sheshBesh}</td>
          </tr>
          <tr>
            <td>1 - 2:</td>
            <td>{player.twoOne}</td>
            <td>{opponent.twoOne}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

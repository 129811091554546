import { omit, random, sample, times } from 'lodash'
import { useEffect, useRef, useState } from 'react'

function useAnimationFrame(callback: () => void) {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number>()

  const animate = () => {
    requestRef.current = requestAnimationFrame(animate)
    callback()
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current)
    }
  }, []) // Make sure the effect runs only once
}

interface ConfettiInfo {
  id: number
  width: string
  height: string
  backgroundColor: string
  top: string
  left: string
  opacity: number
  transform: string
  zIndex: number
  dx: number
  dy: number
  value?: string
}

export function Confetti() {
  const frame: number = -1

  const d = 12 * 1000
  const dy = (1 / d) * 16 * 100
  const amount = 150
  const maxSize = 16
  const minSize = 8
  const colors = [
    '#FAA040',
    '#E94A3F',
    '#a864fd',
    '#29cdff',
    '#78ff44',
    '#ff718d',
    '#fdff6a',
  ]
  const emojis = ['🐒', '👑']

  const [confetti, setConfetti] = useState<ConfettiInfo[]>([])

  const getStyle = (c: ConfettiInfo) => omit(c, ['id', 'dx', 'dy'])

  useAnimationFrame(() => {
    function move(c: ConfettiInfo) {
      const top = parseFloat(c.top) + dy + c.dy
      const left = parseFloat(c.left) + c.dx
      const rotate = parseFloat(c.transform.replace('rotate(', '')) + c.dx * 10
      return {
        ...c,
        top: (top > 100 ? 0 : top) + '%',
        left: (left > 100 ? 0 : left < 0 ? 100 : left) + '%',
        transform: 'rotate(' + rotate + 'deg)',
      }
    }

    setConfetti((prevConfetti) => {
      // console.log('loop', prevConfetti)
      return prevConfetti.map(move)
    })
  })

  useEffect(() => {
    const allConfetti = times<ConfettiInfo>(amount, (id) => {
      const width = random(minSize, maxSize)
      const height = width * 0.4
      const isMonkey = Math.random() > 0.95
      const c: ConfettiInfo = {
        id,
        width: width + 'px',
        height: height + 'px',
        backgroundColor: sample(colors) as string,
        top: Math.random() * 100 + '%',
        left: Math.random() * 100 + '%',
        opacity: random(0.2, 1),
        transform: 'rotate(' + Math.random() * 360 + 'deg)',
        zIndex: random(1, 5),
        dx: random(-0.1, 0.1),
        dy: random(0.05, 0.1),
      }
      if (isMonkey) {
        c.value = sample(emojis)
        c.opacity = 1
        c.backgroundColor = 'transparent'
      }
      return c
    })

    // console.log('allConfetti', allConfetti)

    setConfetti(allConfetti)

    return () => cancelAnimationFrame(frame)
  }, [])

  return (
    <>
      {confetti.map((c) => (
        <div key={c.id} className="confetto" style={getStyle(c)}>
          {c.value}
        </div>
      ))}
    </>
  )
}

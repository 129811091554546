import { User, UserInfo } from '@kfiroo/backgammon-core'
import { doc } from 'firebase/firestore'
import { useFirestore, useFirestoreDocData } from 'reactfire'

export function useUserInfo(user: User) {
  const firestore = useFirestore()
  const ref = doc(firestore, 'users', user.uid)

  const { status, data, error } = useFirestoreDocData(ref)

  return {
    userInfo: data as UserInfo | undefined,
    loading: status === 'loading',
    error,
  }
}

import { GameOverGameState, Model, User } from '@kfiroo/backgammon-core'
import { diceEquals, isDouble } from '@kfiroo/backgammon-core'
import { DocumentReference } from 'firebase/firestore'
import { last } from 'lodash'

import { BaseGameApi, BoardInfo, PlayerType } from './BaseGameApi'

export interface GameOverPlayerInfo {
  email: string
  photoURL: string
  displayName: string
}
export interface GameOverPlayerStats {
  doubles: number
  sheshBesh: number
  twoOne: number
}

export interface GameOverStats {
  [PlayerType.PLAYER]: GameOverPlayerInfo & GameOverPlayerStats
  [PlayerType.OPPONENT]: GameOverPlayerInfo & GameOverPlayerStats
}

export class GameOverGameApi extends BaseGameApi<GameOverGameState> {
  constructor(ref: DocumentReference, model: Model, user: User) {
    super(ref, model, user)
  }

  getBoard(): BoardInfo {
    const state = last(this.model.history)
    return this.buildBoardInfoFromState(state)
  }

  isWinner(): boolean {
    return this.gameState.winner === this.playerIndex
  }

  getWinner(): User {
    return this.model.players[this.gameState.winner] as User
  }

  getWinType(): GameOverGameState['winType'] {
    return this.gameState.winType
  }

  isLoser() {
    return this.playerIndex !== -1 && !this.isWinner()
  }

  getStats(): GameOverStats | null {
    if (!this.isLoser() && !this.isWinner()) {
      return null
    }
    const players = this.getPlayers()

    const history = this.model.history

    const stats = history.reduce<[GameOverPlayerStats, GameOverPlayerStats]>(
      (acc, state) => {
        if (isDouble(state.dice)) {
          acc[state.turn].doubles++
        }
        if (diceEquals(state.dice, 6, 5)) {
          acc[state.turn].sheshBesh++
        }
        if (diceEquals(state.dice, 1, 2)) {
          acc[state.turn].twoOne++
        }
        return acc
      },
      [
        { doubles: 0, sheshBesh: 0, twoOne: 0 },
        { doubles: 0, sheshBesh: 0, twoOne: 0 },
      ],
    )

    return {
      [PlayerType.PLAYER]: {
        email: players[PlayerType.PLAYER]!.email,
        photoURL: players[PlayerType.PLAYER]!.photoURL,
        displayName: players[PlayerType.PLAYER]!.displayName,
        doubles: stats[this.playerIndex].doubles,
        sheshBesh: stats[this.playerIndex].sheshBesh,
        twoOne: stats[this.playerIndex].twoOne,
      },
      [PlayerType.OPPONENT]: {
        email: players[PlayerType.OPPONENT]!.email,
        photoURL: players[PlayerType.OPPONENT]!.photoURL,
        displayName: players[PlayerType.OPPONENT]!.displayName,
        doubles: stats[1 - this.playerIndex].doubles,
        sheshBesh: stats[1 - this.playerIndex].sheshBesh,
        twoOne: stats[1 - this.playerIndex].twoOne,
      },
    }
  }
}

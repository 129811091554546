import './Lobby.css'

import {
  createGame,
  GameInfo,
  GameRules,
  User,
  UserInfo,
} from '@kfiroo/backgammon-core'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { forEach, orderBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useFirestore } from 'reactfire'

import { AuthResult } from './auth/useFirebaseAuth'
import dice from './images/dice.png'
import logo from './images/logo.png'

export function Lobby({ user, loading, errors, signInWithGoogle }: AuthResult) {
  const firestore = useFirestore()
  const startGame = useCallback((rules: GameRules) => {
    console.log('start game ', rules)
    try {
      const model = createGame(rules)
      const ref = doc(firestore, 'games', model.gameId)
      setDoc(ref, model)
        .then(() => {
          console.log('startGame - success', model.gameId)
          window.location.assign(`?gameId=${model.gameId}`)
        })
        .catch((err) => {
          console.error('startGame', err)
        })
    } catch (err) {
      console.error('startGame', err)
    }
  }, [])

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  useEffect(() => {
    if (user) {
      const ref = doc(firestore, 'users', user.uid)
      getDoc(ref)
        .then((doc) => {
          const d = doc.data() as UserInfo
          forEach(d.games, (g) => {
            // @ts-expect-error - joinedAt returns as timestamp
            g.joinedAt = new Date(g.joinedAt.seconds * 1000)
          })
          setUserInfo(d)
        })
        .catch((err) => {
          console.error('getDoc', err)
        })
    }
  }, [])

  const gameEntries = Object.entries(userInfo?.games || {})
  const gamesList = orderBy(
    gameEntries,
    [([, g1]) => g1.joinedAt.getTime()],
    ['desc'],
  )

  return (
    <div className="lobby">
      <div className="logo">
        <img src={logo} alt="logo" />
        <img src={dice} alt="dice" style={{ display: 'none' }} />
      </div>

      <div className="login">
        <Login
          user={user}
          loading={loading}
          errors={errors}
          signInWithGoogle={signInWithGoogle}
        />
      </div>

      <div className="buttons">
        <button
          className="btn"
          disabled={user === undefined}
          onClick={() => startGame('standard')}
        >
          Start Normal Game
        </button>
        <button
          className="btn"
          disabled={user === undefined}
          onClick={() => startGame('roro')}
        >
          Start Roroyal Game
        </button>
      </div>

      <div className="games">
        {gamesList.map(([gameId, game]) => (
          <GameListItem key={gameId} gameId={gameId} game={game} user={user!} />
        ))}
      </div>
    </div>
  )
}

function GameListItem({
  gameId,
  game,
  user,
}: {
  gameId: string
  game: GameInfo
  user: User
}) {
  const playerIndex = game.players.findIndex((u) => u?.email === user.email)
  const gameStatus =
    game.state === 'game-over'
      ? game.winner === playerIndex
        ? 'winner'
        : 'loser'
      : game.state

  const player = game.players[playerIndex]
  const opponent = game.players[1 - playerIndex]

  return (
    <div
      className={`game-list-item ${gameStatus} ${
        playerIndex === -1
          ? ''
          : playerIndex === game.turn
            ? 'player-turn'
            : 'opponent-turn'
      }`}
      onClick={() => window.location.assign(`?gameId=${gameId}`)}
      role="button"
      aria-hidden
    >
      {player && (
        <div className="player">
          <div className="name">{player.displayName}</div>
          <img className="avatar" src={player.photoURL} alt="avatar" />
        </div>
      )}
      <div className="vs">-</div>
      {opponent && (
        <div className="opponent">
          <img className="avatar" src={opponent.photoURL} alt="avatar" />
          <div className="name">{opponent.displayName}</div>
        </div>
      )}
    </div>
  )
}

function Login({ user, loading, errors, signInWithGoogle }: AuthResult) {
  if (loading) {
    return <div>Loading...</div>
  }
  if (Object.keys(errors).length) {
    console.error('login errors', errors)
    return <div>Login error ya kof metumtam</div>
  }
  if (user) {
    return (
      <div>
        <img src={user.photoURL} alt="profile" />
        Hey {user.displayName}
      </div>
    )
  }
  return <button onClick={() => signInWithGoogle()}>Sign In with Google</button>
}

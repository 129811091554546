import {
  BLACK,
  type Dice,
  type GameOverGameState,
  type InProgressGameState,
  type Move,
  type MoveInfo,
  OFF_POINT,
  WHITE,
} from './Model'
import {
  countAtPoint,
  getInitialBoard,
  getPointsWithAtLeast,
  isHit,
} from './board'
import { opponent } from './players'
import { isNil } from 'lodash'

export const getInitialGameState = (): InProgressGameState => ({
  state: 'in-progress',
  turn: WHITE,
  dice: null,
  board: getInitialBoard(),
})

export function isDouble(dice: Dice | null): boolean {
  if (isNil(dice)) {
    return false
  }
  return dice.die1 === dice.die2
}

export const isGameOver = (state: InProgressGameState): boolean => {
  return (
    countAtPoint(state.board, WHITE, OFF_POINT) === 15 ||
    countAtPoint(state.board, BLACK, OFF_POINT) === 15
  )
}

export const getGameOverGameState = (
  state: InProgressGameState,
): GameOverGameState => {
  if (!isGameOver(state)) {
    throw new Error('Game is not over')
  }
  const winner =
    countAtPoint(state.board, WHITE, OFF_POINT) === 15 ? WHITE : BLACK
  const loser = opponent(winner)

  const isBackgammon =
    Math.max(...getPointsWithAtLeast(state.board, loser, 1)) > 18
  const isGammon = countAtPoint(state.board, loser, OFF_POINT) === 0

  const winType = isBackgammon ? 'backgammon' : isGammon ? 'gammon' : 'normal'

  return {
    state: 'game-over',
    winner,
    winType,
  }
}

export function diceToSteps(dice: Dice | null): number[] {
  if (isNil(dice)) {
    return []
  }
  return isDouble(dice)
    ? [dice.die1, dice.die1, dice.die1, dice.die1]
    : [dice.die1, dice.die2]
}

export function stepPermutations(steps: number[]): number[][] {
  if (steps.length === 0) {
    return []
  }
  if (steps.length === 1) {
    return [steps]
  }
  if (steps.length === 2) {
    const [s1, s2] = steps as [number, number]
    if (s1 === s2) {
      return [[s1], [s1, s1]]
    }
    return [[s1], [s2], [s1, s2], [s2, s1]]
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const s = steps[0]!
  if (steps.length === 3) {
    return [[s], [s, s], [s, s, s]]
  }
  if (steps.length === 4) {
    return [[s], [s, s], [s, s, s], [s, s, s, s]]
  }
  return []
}

export function makeAMove(from: number, step: number): Move {
  return {
    from,
    to: Math.max(from - step, 0),
    step,
  }
}

export function addMoveInfo(move: Move, state: InProgressGameState): MoveInfo {
  return {
    ...move,
    isHit: isHit(state.board, state.turn, move),
    isBearOff: move.to <= OFF_POINT,
  }
}

export function getAllMovesFromPoint(
  state: InProgressGameState,
  point: number,
  steps = diceToSteps(state.dice),
): Move[][] {
  const permutations = stepPermutations(steps)

  const allMoves = permutations
    .map((steps) => {
      let from = point
      return steps.map((step) => {
        const move = makeAMove(from, step)
        from -= step
        return move
      })
    })
    .filter((moves) => moves.every((move) => move.from > OFF_POINT))

  return allMoves
}

export function diceEquals(dice: Dice | null, a: number, b: number): boolean {
  if (isNil(dice)) {
    return false
  }
  return (
    (dice.die1 === a && dice.die2 === b) || (dice.die1 === b && dice.die2 === a)
  )
}

import './Game.css'

import { Model, User } from '@kfiroo/backgammon-core'
import { doc, DocumentReference } from 'firebase/firestore'
import React from 'react'
import { useFirestore, useFirestoreDocData } from 'reactfire'

import monkey from '../images/monkey.jpeg'
import { CreatedGame } from './CreatedGame'
import { CreatedGameApi } from './GameApi/CreatedGameApi'
import { GameOverGameApi } from './GameApi/GameOverGameApi'
import {
  getInProgressGameApi,
  PlayingGameApi,
} from './GameApi/InProgressGameApi'
import { GameOver } from './GameOver'
import { InProgressGame } from './InProgressGame'

interface GameModelResult {
  status: 'loading' | 'success' | 'error'
  error?: Error
  model?: Model
  ref: DocumentReference
}
function useGameModel(gameId: string): GameModelResult {
  const firestore = useFirestore()
  const ref = doc(firestore, 'games', gameId)
  const { status, error, data } = useFirestoreDocData(ref)

  // console.log('useGameModel', data)

  if (status === 'success') {
    const model = data as Model
    return { status, error, model, ref }
  }

  return { status, error, model: undefined, ref }
}

interface GameProps {
  user: User
  gameId: string
}

export function Game({ gameId, user }: GameProps) {
  const { status, error, model, ref } = useGameModel(gameId)

  if (model) {
    // @ts-expect-error - for debugging
    globalThis.gameApi = new PlayingGameApi(ref, model, user)
  }

  if (status === 'loading') {
    return <div>Loading game info...</div>
  }

  if (status === 'error') {
    console.error('load game info error', error)
  }

  return <GameInner user={user} model={model} gameRef={ref} error={error} />
}

function GameInner({
  user,
  model,
  gameRef,
  error,
}: {
  user: User
  model?: Model
  gameRef: DocumentReference
  error?: Error
}) {
  if (model?.gameState.state === 'created') {
    const gameApi = new CreatedGameApi(gameRef, model, user)
    return <CreatedGame gameApi={gameApi} />
  }
  if (model?.gameState.state === 'game-over') {
    const gameApi = new GameOverGameApi(gameRef, model, user)
    return <GameOver gameApi={gameApi} user={user} />
  }
  if (model?.gameState.state === 'in-progress') {
    const gameApi = getInProgressGameApi(gameRef, model, user)
    return <InProgressGame key={model?.gameState.turn} gameApi={gameApi} />
  }
  return (
    <div className="monkey">
      <h1>Game not found</h1>
      <img src={monkey} alt="monkey" />
      <button
        style={{ width: 200 }}
        onClick={() => window.location.assign(`/`)}
      >
        Try again
      </button>
      {error && (
        <div style={{ color: 'red', position: 'absolute', top: '100vh' }}>
          <p>load game info error</p>
          <p>{error.message}</p>
        </div>
      )}
    </div>
  )
}

import { type Rules } from './Rules'
import {
  addMoveInfo,
  diceEquals,
  diceToSteps,
  getInitialGameState,
  isDouble,
  makeAMove,
} from '../common'
import { rollDice } from '../random'
import { cloneDeep, isNil, times, uniq } from 'lodash'
import {
  type AvailableMoves,
  BAR_POINT,
  type Dice,
  type InProgressGameState,
  type Move,
} from '../Model'
import {
  countAtPoint,
  getPointsWithAtLeast,
  isOccupied,
  isOnBar,
  isReadyToBearOff,
  opponentPoint,
} from '../board'
import { opponent } from '../players'
import { standardRules } from './standard'

export const roroRules: Rules = {
  getInitialGameState,

  getMovesWithSteps(state, steps) {
    if (steps.length === 0) {
      return {}
    }

    if (isTwoOne(state.dice)) {
      return {}
    }

    if (isOnBar(state.board, state.turn)) {
      const mustPlay = uniq(steps).filter(
        (p) => !isOccupied(state.board, opponent(state.turn), p),
      )
      if (mustPlay.length > 0) {
        return {
          [BAR_POINT]: mustPlay.map((step) => [
            addMoveInfo(makeAMove(BAR_POINT, step), state),
          ]),
        }
      }
      return {}
    }

    if (isReadyToBearOff(state.board, state.turn)) {
      const mustPlay = uniq(steps).filter(
        (p) => countAtPoint(state.board, state.turn, p) > 0,
      )
      if (mustPlay.length > 0) {
        return mustPlay.reduce<AvailableMoves>((acc, from) => {
          acc[from] = [[addMoveInfo(makeAMove(from, from), state)]]
          return acc
        }, {})
      }
      if (isSheshBesh(state.dice)) {
        return sheshBeshMoves(state, true)
      }
    }

    if (isSheshBesh(state.dice)) {
      return sheshBeshMoves(state, false)
    }

    return standardRules.getMovesWithSteps(state, steps)
  },

  playTurnMoves(state, moves) {
    const steps = diceToSteps(state.dice)
    const expectedMoves = steps.length

    if (moves.length > expectedMoves) {
      throw new Error('Too many moves')
    }

    const newState = moves.reduce(
      (s, move) => roroRules.playSingleMove(s, move),
      cloneDeep(state),
    )

    const newSteps = standardRules.updateSteps(state, moves, steps)

    if (moves.length < expectedMoves) {
      const availableMoves = roroRules.getMovesWithSteps(newState, newSteps)
      if (Object.keys(availableMoves).length > 0) {
        throw new Error('Not all moves were made')
      }
    }

    // console.log('newState', JSON.stringify(newState, null, 4))

    return newState
  },

  finalizeTurn(state) {
    const turn =
      isDouble(state.dice) || isSheshBesh(state.dice)
        ? state.turn
        : opponent(state.turn)

    return {
      ...state,
      turn,
      dice: null,
    }
  },

  rollDice(state) {
    if (!isNil(state.dice)) {
      throw new Error('Dice already rolled')
    }
    const dice = rollDice()
    return {
      ...state,
      dice,
    }
  },

  playSingleMove(state, move) {
    return standardRules.playSingleMove(state, move)
  },

  updateSteps(state, moves, steps) {
    let newSteps = [...steps]
    moves.forEach((move) => {
      const si = newSteps.indexOf(move.step)
      if (si === -1 && isSheshBesh(state.dice)) {
        newSteps.pop()
      }
      newSteps = newSteps.filter((_, i) => i !== si)
    })
    return newSteps
  },
}

function isSheshBesh(dice: Dice | null): boolean {
  return diceEquals(dice, 6, 5)
}

function isTwoOne(dice: Dice | null): boolean {
  return diceEquals(dice, 2, 1)
}

function sheshBeshMoves(
  state: InProgressGameState,
  allowBearOff: boolean,
): AvailableMoves {
  const playerPoints = isOnBar(state.board, state.turn)
    ? [BAR_POINT]
    : getPointsWithAtLeast(state.board, state.turn, 1)

  const movesFromPoint = playerPoints.reduce<AvailableMoves>((acc, from) => {
    const allMoves = times(24, (to) => to + 1).reduce<Move[][]>(
      (movesAcc, to) => {
        if (to === from) {
          return movesAcc
        }
        if (isOccupied(state.board, opponent(state.turn), opponentPoint(to))) {
          return movesAcc
        }
        movesAcc.push([makeAMove(from, from - to)])

        return movesAcc
      },
      [],
    )
    if (allMoves.length > 0) {
      acc[from] = allMoves.map((moves) =>
        moves.map((move) => addMoveInfo(move, state)),
      )
    }
    if (allowBearOff) {
      acc[from] = acc[from] ?? []
      acc[from]?.push([addMoveInfo(makeAMove(from, from), state)])
    }
    return acc
  }, {})

  return movesFromPoint
}
